.footer {
    flex-shrink: 0;
    width: 100%;
    height: 40vh;
    background: #0018A5;
    display: flex;
    flex-direction: column;
    justify-content: space-around;    
    align-items: center;
    font-family: 'Red Hat Display';
}
.footerContainer {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.socialMediaBox {
    display: flex;
    text-align: center;
    height: 80%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}
.svenFooter {
    display: flex;
    color: white;
    font-size: 10px;
    justify-content: space-evenly;
    align-items: flex-start;
    
    width: 30vw;
    margin: -5% auto;
}

.socialMedia {
    color: white;
    font-size: 12px;
    font-weight: 400;
}

/* .linkedin {
    font-size: 12px;
} */

.logoFooter {
    width: 20px;
}

.socialMediaText{
    color: white;
}

.socialMediaIcon {
    color: white;
    margin: 0px 20px;
}

.final {
    border-top: 1px solid white;
    color: white;
    width: 85vw;
    margin: 0 auto;
    padding-top: 1%;
    font-size: xx-small;
    font-family: var(--font-general-regular);
}

@media (min-width: 592px) {
    .footer {
        justify-content: space-between;
    }    
    .socialMediaBox {
        width: 100vw;
    }
    .final {
        border-top: 1px solid white;
        width: 85vw;
        margin: 0 auto;
        padding-top: 1%;
        font-size: x-small;
    }
}
@media (min-width: 992px) {
   
    .footerContainer {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: 15vh;
    }
    .socialMediaBox {
        width: unset;
        height: 100%;
        justify-content: space-between;
    }
    .footer {
        height: 287px;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: unset;
    }    

    .svenFooter{
        font-size: 17px;
        width: 20%;
        margin: unset;
    }

    .logoFooter{
        width: 40px;
    }

    .socialMediaIcon:hover {
        color:rgb(139, 145, 149);
    }
    
    .socialMedia {
        font-size: 14px;
    }
    
    .final {
        border-top: 1px solid white;
        width: 85vw;
        margin: 0 auto;
        padding-top: 1%;
        font-size: x-small;
    }
}
