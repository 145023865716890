.swiperImage {
    width: 130%;
    position: relative;
    right: 45px;
    height: 76%;
}

.itemContainer {
    margin: 2%;
    height: 400px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    
    overflow: hidden;
}
.swiperBrands {
    margin: 0 0 0 5% !important;
    width: 100vw;
}

.swiperText h3 {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 85.714% */
    letter-spacing: -1.05px;
}
.swiperText {
    padding: 30px 20px 0;
}
.swiperMachine {
    color: #4A4A4A;
    font-family: General Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
}

.videoItem {
    width: 100%;
    object-fit: cover;
    height: 90%;
}


@media (min-width: 768px) {
    .itemContainer {
        height: 500px;
    }
    .swiperBrands {
        padding: 2% 4% 0%!important;
        margin-bottom: 10% !important;
        width: 100vw;
    }
    
    .swiperText h3 {
        font-size: 30px;
    }
    .swiperMachine {
        font-size: 18px;
    }
}


@media (min-width: 1024px) {
    /* .itemContainer {
        width: 30vw;
        margin: unset;
        border: unset;
    } */
    .swiperText h3 {
        font-size: 35px;
    }
    .swiperMachine {
        font-size: 20px;
    }
}

@media (min-width: 1600px) {
    .itemContainer {
        height: 580px;
    }
}