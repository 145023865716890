/* .mindaContainer {
    display: flex;
    flex-direction: row;
} */

.mindaInfo {
    padding: 10%;
}

.mindaYoutubeContainer {
    width: 100%;
    height: 100vh;
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin: 10% auto 0;
}

.mindaYoutube {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (min-width: 682px) {

    .mindaYoutubeContainer {
        margin: 5% auto 0;
    }
    .mindaYoutube {
        height: 75%;
    }
    .mindaInfo {
        margin-top: -15%;
    }
    
}

@media (min-width: 1000px) {

    .mindaYoutubeContainer {
        margin: 5% auto 0;
    }
    .mindaYoutube {
        height: 65%;
    }
    .mindaInfo {
        padding: 10% 15%;
        /* margin-top: -25%; */
    }
    
}