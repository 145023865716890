
.homeDescription {
    color: #F5F5F5;
    font-style: normal;
    z-index: 50;
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-shrink: 0;
    position: absolute;
    bottom: 200px;
    text-align: center;
}
.homeTitle {
    font-size: 35px;
    font-weight: 600;
    line-height: 115%; /* 69px */
    letter-spacing: -0.8px;
}

.homeImageMobile {
    width: 100%;
    /* height: 56vh; */
    height: 95vh;
    filter: brightness(0.7) blur(0.5px) !important;
    position: relative;
}

.homeImage {
    height: 96vh;
    width: 90%;
    display: none;
    filter: brightness(0.7) blur(0.5px) !important;
    position: relative;
}

.homeSubtitle {
    font-family: 'General Sans regular';
    color: #FFF;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 132%; /* 30.36px */
    text-align: center;
    margin: auto;
}

@supports(object-fit: cover){
    .homeImage, .homeImageMobile {
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
}
@media (min-width: 876px) {

    .homeImageMobile {
        display: none;
    }
    
    .homeImage {
        display: block;
    }
}

@media (min-width: 1400px){
    
    .homeTitle {
        font-size: 58px;
        letter-spacing: -1.8px;
    }
    .homeDescription { 
        top: 63%;
    }
    .homeSubtitle{
        font-size: 23px;
        text-align: left;
    }

}

@media (min-width: 1600px){
    
    .homeTitle {
        margin: 0;
    }

}
