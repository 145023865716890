.brands {
    width: 100%;
}
.companyContainer {
    background-color: #000;
}

.companyContainer {
    color: white;
    height: 100%;
    margin-bottom: -1.9%;
}

.companyIntroContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 13% 0 10%;
}
.companyIntro {
    text-align: center;
    padding: 10%;
}

.companyTitle {
    font-size: 31px;
    font-style: normal;
    line-height: 106.5%;
    letter-spacing: -1.2px;
    font-weight: 600;
}
.companySubtitle {
    width: 72%;
    margin: auto;
    color: #5991FF;
    font-family: var(--font-general-light);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.companyIntroContainer img {
    width: 70%;
}

.about {
    width: 65vw;
    margin: auto;
    padding: 4% 0 25%;
}
.about p {
    font-family: var(--font-general-regular);    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.about h4 {
    font-family: var(--font-general-semibold);
    font-size: 30px;
    font-style: normal;
    letter-spacing: -1.2px;
    margin-bottom: 5%;
    font-weight: 600;
}
@media (min-width: 992px){
    .companyTitle {
        font-size: 40px;
    }
    .companyIntroContainer img {
        width: 33%;
    }
    
    .companyIntroContainer {
        flex-direction: row;
    }

    .companyIntroContainer img {
        width: 33.3%;
    }
    .companyIntro {
        padding: 3%;
        width: 33.3%;
    }
    .about h4 {
        font-size: 40px;
    }
    .about p {
        font-size: 21px;
    }
}