.itemDetailContainer {
    display: flex;
    padding: 2%;
}
.detailImageContainer {
    width: 50%;
    overflow: hidden; 
    text-align: center;
    height: inherit;
}
.detailImage {
    width: 110%;
    width: 190%;
    display: inline-block; /* Hace que la imagen se comporte como un elemento en línea */
    transform: translateX(-35%);
}
.textContainer {
    width: 50%;
    padding: 4%;
}

.modal-dialog {
    max-width: 96vw!important;
    width: 80vw;
}

.modal-content {
    border: none;
}

.detailText h3 {
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -1.35px;
}

.detailMachine {
    color: #4A4A4A;
    font-family: var(--font-general-medio);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 136.364% */
}

.detailDescription {
    font-family:  var(--font-general-regular);
    font-size: 22px;
    line-height: normal;
    line-height: 28px;
    padding-top: 3%;
}

.dataTitle {
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 115.385% */
}

.dataSubtitle {
    color: #0015BF;
    font-family:  var(--font-general-medio);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px; /* 175% */
}

.dataTableRow {
    display: flex;
}
.dataTableRow p {
    width: 50%;
    padding: 3% 3% 1% 0;
    border-top: 1px black solid;
}

.carousel {
    height: 100%;
}
.carousel-indicators [data-bs-target] {
    width: 14px !important;
    height: 14px !important;
    border-radius: 50% !important;
    background-color: #B9B9BA !important;
    opacity: 1;
    
    margin-right: 7px !important;
    margin-left: 7px !important;
}
.carousel-indicators .active {
    background-color: #464646 !important;
}

.carousel-indicators {
    margin-left: unset;
    margin-right: unset;
    margin-bottom: 0.6rem;
}

.carousel-control-prev, .carousel-control-next {   
    margin: auto !important;
    height: 50px !important;
}

.modalSwiper {
    display: flex;
    width: 60vw !important;
    margin: auto !important;
    height: 65px;
    background: white;
    overflow: hidden;
    position: absolute;
    
    top: -100px;
    left: 17.5%;
    overflow: hidden;
    margin-bottom: 50px !important;
    
    padding: 0 2px;
    border-radius: 40px;
}

.modalCategorys {
    text-align: center;
    margin: 8% auto;
    
    width: fit-content;
    padding: 0;
}
.modal-header {
    display: none !important;
}
.modal-body {
    padding: 0 !important;
}
.modal.show .modal-dialog {
    transform: none;
    position: relative !important;
    top: 25%;
    margin-bottom: 20%;
}
.modalCategorysActive {
    border-radius: 45px;
    background: #0018A5;
    color: white;
    margin: 1% auto;
    height: 92%;
    padding: 7% 4%;
    font-size: 18px;
    width: fit-content;
}

.modal-content {
    border-radius: 25px !important;
}

@media (max-width:1550px){
    .modalSwiper{
        height: 60px;
    }
}
@media (max-width:600px){
    .itemDetailContainer{
        flex-direction: column;
    }
    .detailImageContainer{
        width: 100%;
    }
    .detailImage {  
        margin-top: 6%;
    }
    .detailDescription {
        font-size: 17px;
        line-height: normal;
        line-height: 25px;
    }
    .textContainer {
        width: 100%;
    }
    .modalSwiper{
        height: 45px;
        top: -75px;
        width: 80vw !important;
        left: 6%;
    }
    .modalCategorys{
        margin: 12% auto;
        font-size: 12px;
    }
    .modalCategorysActive {
        padding: 9% 4%;
        height: 89%;
        font-size: 12px;
        margin: 2.5% auto;
    }
    .detailText h3 {
        font-size: 35px;
    }
}