.blurred {
    filter: blur(3px);
}
  
.logo {
    width: 6.4375rem;
}

#auxiliar::after {
    display: none;
}

.closeButton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #333;
    right: 7px;
    top: 7px;
    position: absolute;
}
  
.companyButton, .brandsButton {
    font-size: 0.7rem;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    line-height: 30px; 
    letter-spacing: -0.48px;
}

.separationLine{
    border-right: black 0.01px solid;
    height: 10px;
}
a:hover,
.activeButton {
    color: black !important;
}
.inactiveButton {
    color: rgba(0, 0, 0, 0.65);
}
.navbar {
    display: flex;    
    margin-left: 10%;
    margin-right: 10%;
    z-index: 2;
}

.rightNavbar {
    width: 25vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dropdown {
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 70vw;
    margin: 20% 15%;
}
.dropdown a { 
    font-family: 'Red Hat Display';
    color: black !important;
    /* margin: 1%; */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px; /* 191.667% */
    letter-spacing: -0.6px;
}

@media (min-width: 678px) {
    .logo {
        
        width: 10.4375rem;
        height: unset;
    }
    
    .brandsButton, .companyButton {
        font-size: 0.8rem;
    }
    
    .rightNavbar {
        display: flex;
        flex-direction: row;
        width: 15%;
        justify-content: space-evenly;
        align-items: center;
    }
    .dropdown {
        height: unset;
        width: 100%;
        padding: 3% 10%;
        z-index: 20 !important;
        position: absolute !important;
        background: white;
        margin: 0;
        margin-top: -6px;
    }
    
}


@media (min-width: 1024px) {
    .brandsButton, .companyButton {
        font-size: 13px;
        letter-spacing: normal;
    }
    .logo {
        width: 150px;
    }
    
    .dropdown a {        
        color: rgba(0, 0, 0, 0.65) !important;
    }
    .dropdown a:hover {        
        color: black !important;
    }
    
    .navbar {
        overflow: hidden !important;
    }
}