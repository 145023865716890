.videoCorrpal {
    width: 100%;
    overflow: hidden;
    margin: -10% auto 10%;
}

@media (min-width: 768px) {

    .videoCorrpal {
        margin: 5% auto 10%;
        object-fit: cover;
        height: 92vh;
        object-position: bottom;
    }
    
}

@media (min-width: 1024px) {

    /* .videoCorrpal {
        margin: 5% auto 10%;
        height: 65%;
    } */
    
}