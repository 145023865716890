.brandsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 25%;
}
.brandsModel {
    border: none;
    background: transparent;
    margin: 8% 0% 0 8%;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px; /* 136.364% */
    letter-spacing: -0.66px;
    
    padding-left: 3%;

    margin: 8% 0% 0 0%;
    color: #000;
    font-size: 11px;
}
.brandsModelActive{
    text-decoration-line: underline;
}
.descriptionBrand {
    font-size: 24px;
    font-weight: 600;
    line-height: 123%;
    letter-spacing: -1.35px;
    min-height: 50px;
}
.textDescriptionBrand {
    color: #898989;
    font-size: 10px;
    font-weight: 600;
    line-height: 123%;
    width: 76vw;
}
.descriptionContainer {
    padding: 0 0 0 10%;
}
.machineButton {
    border-radius: 0px !important;
}

.brandingLogo {
    width: 40vw;
    margin: 15%;
}

.toBig {
    width: 40vw;
}   

.divBrandingLogo {
    height: 200px;
    text-align: center;
}
.allModels {
    color: #000;
    font-family: 'Red Hat Display';
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 90.909% */
    letter-spacing: -0.99px;
    padding: 5% 6% 1% 6%;
}

@media (min-width: 600px){

    .brandingLogo {
        width: 20vw;
        margin: 8% 0;
    }
}
@media (min-width: 768px){
    .categoryByMachine {
        flex-direction: row;
        margin: 5% 0 0 5%;
        max-width: 100%;
    }
 
    .toBig {
        width: 12vw;
    }   
    .brandsModel {
        font-size: 20px;
        padding-left: unset;
    }
}

@media (min-width: 1024px){
    .brandsContainer {
        padding-bottom: unset;
    }
    .descriptionBrand {
        font-size: 45px;
    }
    .textDescriptionBrand {
        font-size: 26px;
    }
    
    .brandsModel {
        margin: 5% 0% 0 8%;
        font-size: 22px;
    }
    
    .divBrandingLogo {
        height: unset;
    }
}